export const BACKEND_API_HOST = 'backend.h2yo.co';
export const BACKEND_API_HOST_TEST = 'portal-backend-dev-app.s2abp205tqn.eu-gb.codeengine.appdomain.cloud';

let ENV_VAL = 'DEV';
if (process.env.NODE_ENV === 'production' && !window.location.hostname.includes('appdomain.cloud')) {
  ENV_VAL = 'PROD';
} else if (process.env.NODE_ENV === 'production' && window.location.hostname.includes('appdomain.cloud')) {
  ENV_VAL = 'TEST';
}

export const ENV = ENV_VAL;